import React from "react";
import { graphql } from "gatsby";
import * as styles from "../../styles/leadership.module.scss";
import DefaultLayout from "../../layouts";
import Seo from "../../components/seo";

const LeadershipPage = ({ data }) => {

    const leaders = data.directus.leaders;

    return (
        <DefaultLayout>
            <Seo
                title="Leadership"
                description="Novus Home Mortgage Leadership Team" />
            <main>
                <div className={styles.containerLeadership}>
                    {leaders.map((leader, idx) => (
                        <div key={idx} className={styles.leader}>
                            <div className={styles.imageContainer}>
                                <img src={leader.headshot.imageFile.childImageSharp.gatsbyImageData.images.fallback.src} />
                            </div>
                            <div className={styles.copy}>
                                <div className={styles.copyWrapper}>
                                    <div className={[styles.headline, styles.name].join(' ')}>{leader.firstName} {leader.lastName}</div>
                                    <div className={styles.title}>{leader.title}</div>
                                    <div dangerouslySetInnerHTML={{ __html: leader.history }}></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </main>
        </DefaultLayout>
    );
};
export const query = graphql`
query leaders {
    directus {
      leaders {
        firstName
        lastName
        title
        history
        headshot {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

export default LeadershipPage;